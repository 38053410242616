import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

const { bedroomProducersBlog } = images

const SorooshMohassesi = () => (
	<section id='soroosh-mohassesi'>
		<Container>
			<Row className='align-items-center'>
				<Col lg={8} className='text-center'>
					<FontAwesomeIcon icon={faQuoteLeft} />
					<div className='testimonial text-center'>
						<p>
							Do you also struggle with programming MIDI parts using a MIDI keyboard? 
							Coming up with melodies on a keyboard can be difficult if it&apos;s not your main instrument. 
							You may have the perfect melody in your head and be able to hum it, 
							but translating that to a keyboard might still be challenging. 
							Pitch Perfekt just comes in handy in these situations, 
							as it allows you to sing into your mic and translate it to MIDI in real time. 
							And, of course, you can load up your favourite synth or sampled instrument and use your voice as a MIDI controller. 
							This is an exciting way to capture your musical ideas &mdash; 
							similar to the good old voice memo app but with direct MIDI transcription. 
							It&apos;s clear that they&apos;ve put a lot of thought and work into it.
						</p>
						<h3>Soroosh Mohassesi.</h3>
						<span>Composer and Guitarist</span>
					</div>
				</Col>
				<Col lg={4} className='text-center'>
					<div className='testimonial-img'>
						<a href='https://bedroomproducersblog.com/2024/12/25/pitch-perfekt-algoriffix/' target='_blank' rel='noreferrer'>
							<Image src={bedroomProducersBlog} alt='Soroosh Mohassesi' width={300} className='mt-4' roundedCircle />
						</a>
					</div>
				</Col>
			</Row>
		</Container>
	</section>
)

export default SorooshMohassesi
