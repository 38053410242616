import logo from './logo.svg'
import checkmark from './yes.svg'
import cross from './no.svg'
import arrow_down from './arrow_down.svg'
import unlock from './unlock.svg'
import macAppStoreBlack from './Download_on_the_Mac_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import macAppStoreWhite from './Download_on_the_Mac_App_Store_Badge_US-UK_RGB_wht_092917.svg'

const svgs = {
	logo,
	checkmark,
	cross,
	arrow_down,
	unlock,
	macAppStoreBlack,
	macAppStoreWhite
}

export default svgs
