import React, { useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

const {
  benjaminImage,
	eduardoImage,
	juanCarlosImage,
	leoImage,
	luisImage,
	troyImage,
  // viktorImage
} = images

const Testimonial = () => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)

  const settings1 = {
    className: 'testimonial-main',
    slidesToShow: 1,
		slidesToScroll: 1,
		// autoplay: true,
		fade: true,
		// speed: 600,
		dots: false,
		arrows: false
}

  const settings2 = {
    className: 'testimonial-main-img',
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    // autoplaySpeed: 1500,
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
    pauseOnHover: true
}
  
  return (
  <section id='testimonial'>
    <Container>
      {/*
      <Row>
        <Col lg={12} className='text-center overview-head'>
          <h2>TESTIMONIALS</h2>
        </Col>
      </Row>
      */}
      <Row>
        <Col lg={12} className='text-center'>
          <FontAwesomeIcon icon={faQuoteLeft} />
          <Slider {...settings1} ref={(slider) => setNav1(slider)} asNavFor={nav2}>
            <div className='testimonial-item text-center'>
              <p>I usually play around with Transkr V2, making something unconventional. I discover new ideas every time. It is really fun! I will definitely create more with it in the future.</p>
              <h3>Ben.</h3>
              <span>Musician and Music Producer</span>
            </div>
            <div className='testimonial-item text-center'>
              <p>The Transkr V2 plug-in is amazing! It is really fun to play and experiment with, and do everything that I usually do with a MIDI controller and my guitar.</p>
              <h3>Lalo.</h3>
              <span>Singer-Songwriter</span>
            </div>
            <div className='testimonial-item text-center'>
              <p>There are days when I experience a creative slowdown and wish for a little help. Transkr V2 helps me overcome such moments of inspirational low.</p>
              <h3>Juan Carlos.</h3>
              <span>Bass Player and Composer</span>
            </div>
            <div className='testimonial-item text-center'>
              <p>Creating a track from scratch with Transkr V2 is both unusual and intricate. It provides its own perspective on harmony and melody, and stimulates me to think outside of the box.</p>
              <h3>Leo.</h3>
              <span>Musician and Composer</span>
            </div>
            <div className='testimonial-item text-center'>
              <p>It is wonderful! I no longer need to think about how to put my musical ideas to paper. I just sing a random melody and Transkr V2 does the writing for me.</p>
              <h3>Luis.</h3>
              <span>Singer-Songwriter and Music Producer</span>
            </div>
            <div className='testimonial-item text-center'>
              <p>It took me a little while to grasp it, but when it clicked, I saw how useful it was. Transkr V2 helps me figure out scales and harmony, and I can drag MIDI data directly into my DAW.</p>
              <h3>Troy.</h3>
              <span>Electronic Music Producer</span>
            </div>
            {/*
            <div className='testimonial-item text-center'>
              <p>Why spend hours transcribing songs by ear when you can have Transkr V2 do the heavy lifting? Now I can focus on making music instead of writing sheet music. Love it!</p>
              <h3>Viktor.</h3>
              <span>Musician and Bandleader</span>
            </div>
            */}
          </Slider>
        </Col>
      </Row>
      <Row>
        <Col lg={3} className='m-auto'>
          <Slider {...settings2} ref={(slider) => setNav2(slider)} asNavFor={nav1}>
            <div className='testimonial-img-item text-center'>
              <Image src={benjaminImage} alt='Benjamin' fluid />
            </div>
            <div className='testimonial-img-item text-center'>
              <Image src={eduardoImage} alt='Eduardo' fluid />
            </div>
            <div className='testimonial-img-item text-center'>
              <Image src={juanCarlosImage} alt='Juan Carlos' fluid />
            </div>
            <div className='testimonial-img-item text-center'>
              <Image src={leoImage} alt='Leo' fluid />
            </div>
            <div className='testimonial-img-item text-center'>
              <Image src={luisImage} alt='Luis' fluid />
            </div>
            <div className='testimonial-img-item text-center'>
              <Image src={troyImage} alt='Troy' fluid />
            </div>
            {/*
            <div className='testimonial-img-item text-center'>
              <Image src={viktorImage} alt='Viktor' fluid />
            </div>
            */}
          </Slider>
        </Col>
      </Row>
    </Container>
  </section>
  )
}

export default Testimonial
