import { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { Cookies } from 'react-cookie-consent'
import './style.scss'

// const promoCode = 'NY2025'
// const url = `https://buy.stripe.com/9AQfZifzkeKUbcYfZ4?prefilled_promo_code=${promoCode}`
// const kvr = 'https://www.kvraudio.com/marketplace/transkr-v2-by-algoriffix'

const cookieName = '_arx_update_20250126'

const Promo = () => {
  const [show, setShow] = useState(!Cookies.get(cookieName))

  const handleDismiss = () => {
    Cookies.set(cookieName, true)
    setShow(false)
  }

  return (
    <Alert className='promo-container' variant='' show={show}>
      <div className='promo-content'>
        <Alert.Heading>Product Update</Alert.Heading>
        {/*
        <p>
          Instructions for Cubase Pro can now be found <Alert.Link href='/guide/cubase-pro'>here</Alert.Link>.
        </p>
        */}
        {/*
        <p>
          Snatch a licence for <Alert.Link href='#transkr-v2-features'>Tranksr V2</Alert.Link> at a discount of 25% using this <Alert.Link href={url}>link</Alert.Link>. 
          Promo ends January 3, 23:59 CET.
        </p>
        */}
        {/**/
        <p>
          <Alert.Link href='#pitch-perfekt-features'>Pitch Perfekt</Alert.Link> version 1.3.1 with tuner, spectrum analyser and vocal dynamics control is now available for <Alert.Link href='#pitch-perfekt-download'>download</Alert.Link>. 
          Find more information in the updated <Alert.Link href='https://www.algoriffix.com/guide/pitch-perfekt'>user guide</Alert.Link>.
          {/*
          {' '}
          <Alert.Link href='#pitch-perfekt-download'>Download</Alert.Link> it completely free of charge for both Mac and PC. 
          {' '}
          <Alert.Link href='#transkr-v2-features'>Transkr V2</Alert.Link> has been <Alert.Link href='#transkr-v2-download'>updated</Alert.Link> to version 2.3.0.
          */}
        </p>
        /**/}
      </div>
      <div className=''>
        <Button className='dismiss-button' variant='' onClick={handleDismiss}>
          Dismiss
        </Button>
      </div>
    </Alert>
  )
}

export default Promo
