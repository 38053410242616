import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../../assets'
// import './style.scss'

const {
  reaperPreferencesAudioDevice,
  reaperPreferencesAudioMidiInputs,
  reaperAddFxToTrack1,
  reaperFxTrack2,
  reaperTranskrV2
} = images

const reaperUserGuideView = () => {
	window.gtag_sendEvent('user_guide/REAPER')
}

const ReaperUserGuide = () => {
  useEffect(() => reaperUserGuideView(), [])

  return (
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>REAPER</h2>
        <p>
          The screenshots were taken on a Windows PC, yet the steps are essentially the same on a Mac.
        </p>

        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the audio settings and select your audio interface as the audio device. 
          We recommend using the drivers from the manufacturer, as they are most reliable and allow for the lowest latency. 
          Set the sampling rate to 48 kHz and the buffer size to 128 samples. 
          Going below 128 is not recommended, because this may result in underruns and negatively impact audio quality.
        </p>
        <Row className='justify-content-md-center'>
          <Col lg={10} className='text-center'>
            <Image className='pb-4' src={reaperPreferencesAudioDevice} alt='Audio Device' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the MIDI settings and make sure loopMIDI Port or IAC Driver Bus is listed as a MIDI input device.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={10} className='text-center'>
          <Image className='pb-4' src={reaperPreferencesAudioMidiInputs} alt='MIDI Inputs' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Insert a new track and add Transkr V2 as an effect. Record arm the track and choose the mono input from your audio interface 
          with your mic or instrument connected as the external audio input source. 
          Don&apos;t forget to activate record monitoring.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={8} className='text-center'>
          <Image className='pb-4' src={reaperAddFxToTrack1} alt='Add FX' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Insert a virtual instrument on another track for audio output. 
          The selected instrument will receive MIDI data from Transkr V2 via the MIDI port.
        </p>
        <Row className='justify-content-md-center'>
        <Col lg={12} className='text-center'>
          <Image className='pb-4' src={reaperFxTrack2} alt='Virtual Instrument' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Open Transkr V2 and
        </p>
        <ul>
          <li>
            set the line input to the channel associated with your audio input (only relevant for stereo input)
          </li>
          <ul>
            <li>
              Make sure the VU meter reacts to your input and reaches 100% without saturating.
            </li>
            <li>
              Use the gain knob on your audio interface and/or the plug-in to adjust the input level.
            </li>
            <li>
              Use the &quot;pass thru&quot; button to verify that your input audio is free of clicks and pops.
            </li>
          </ul>
          <li>
            set the MIDI output to the MIDI port that shall receive MIDI data from the plug-in
          </li>
          <li>
            select one of the defaults from the preset list that best represents your instrument
          </li>
        </ul>
        <Row className='justify-content-md-center'>
        <Col lg={10} className='text-center'>
          <Image className='pb-4' src={reaperTranskrV2} alt='Transkr V2 GUI' fluid />
          </Col>
        </Row>

        <p>
          You should now be all set to start using Transkr V2 in REAPER.
        </p>
      </Container>
    </section>
  )
}

export default ReaperUserGuide
