import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'

const Copyright = () => (
	<div id='copyright'>
		<Container>
			<Row>
				<Col lg={12} className='text-center copyright-text'>
					<p>
            &copy; 2020&ndash;2024 <a href='/'>Algoriffix AB</a>
					</p>
				</Col>
			</Row>
		</Container>
	</div>
)

export default Copyright
