import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Cookies } from 'react-cookie-consent'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import './style.scss'

const cookieName = '_arx_hide_promo'
const supportUrl = 'mailto:support@algoriffix.com?subject=Transkr%20V2%20License&body=Please%20send%20me%20the%20license%20key.'

const afterPayment = (sessionId) => {
  /*
  window.gtag_sendEvent('after_payment', {
    session_id: sessionId
  })
  */

  window.gtag_sendEvent('purchase', {
    currency: 'USD',
    value: 39.00,
    transaction_id: sessionId,
    items: [
      {
        item_name: 'Transkr V2 License',
        quantity: 1
      }
    ]
  })

  window.fbq_standardEvent('Purchase', {
    currency: 'USD',
    value: 39.00
  })

  Cookies.set(cookieName, true)
}

const ThankYou = ({ product }) => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id') || searchParams.get('tx') || ''

  useEffect(() => {
    if (sessionId  === '' /* || !(sessionId.startsWith('cs_test_') || sessionId.startsWith('cs_live_')) */ /* || sessionId.length > 255 */) {
      return navigate('/', { replace: true })
    }

    afterPayment(sessionId)
  }, [navigate, sessionId])

  return (
    <section id='thank-you'>
      <Container className='thank-you-container'>
        <Row className='thank-you-p'>
          <Col lg={6} md={10} className='text-center m-md-auto thank-you-svg'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </Col>
          <Col lg={6} className='thank-you-text'>
					  <h2>Thank You</h2>
					  <h3>You are why we exist</h3>
					  <p>
						  Thank you for purchasing a license for {product}! We hope you will find inspiration in it. 
              If you used PayPal, a receipt for your purchase should have been emailed to you. Log into your PayPal account to view the details.
					  </p>
            <p>
              You will shortly receive the license key with instructions for how to unlock the full version. 
              If you do not hear back from us within the next 48 hours, please reach out to <a href={supportUrl} className='support-email'>support@algoriffix.com</a>.
            </p>
            <a href='/' className='go-back-button'>Go Back</a>
				  </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ThankYou
