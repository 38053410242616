import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

const { viktorDiazImage } = images

const ViktorDiaz = () => (
	<section id='viktor-diaz'>
		<Container>
			<Row className='align-items-center'>
				<Col lg={8} className='text-center'>
					<FontAwesomeIcon icon={faQuoteLeft} />
					<div className='testimonial text-center'>
						<p>
							Why spend hours transcribing songs by ear when you can let technology do the heavy lifting? 
							As a professional musician and bandleader, 
							I often find myself dedicating countless hours to transcribing and writing sheet music &mdash; instead of creating music. 
							Transkr V2 has significantly reduced this time by allowing me to play my guitar directly into the DAW and 
							import MIDI files into my notation program. 
							I have been searching for similar physical products, but with Transkr V2 
							I don&apos;t need to modify my guitar with expensive pickups or study proprietary MIDI apps. 
							I simply plug my guitar into an audio interface and Transkr V2 translates it to MIDI in real time. 
							I can&apos;t explain the magic behind the algorithm, but it accurately tracks notes, chords 
							and even beats, and it works with virtually any guitar. 
							Amazing!
						</p>
						<h3>Viktor Diaz.</h3>
						<span>Musician and Bandleader</span>
					</div>
				</Col>
				<Col lg={4} className='text-center'>
					<div className='testimonial-img'>
						<Image src={viktorDiazImage} alt='Viktor Diaz' width={300} className='mt-4' roundedCircle />
					</div>
				</Col>
			</Row>
		</Container>
	</section>
)

export default ViktorDiaz
