import logoText from './logotext.png'
import robotHead from './robot_head_splash.png'
import notes from './notes.png'
import robotLogo from './robot_logo.png'
import transkrV2Gui from './Transkr_V2_GUI.png'
import fretboardGui from './Fretboard_GUI.png'
import scanToPay from './scan_to_pay.png'
import promoCodeImage from './promo_code.png'
import westerbergLogo from './Westerberg.png'

import benjaminImage from './benjamin.png'
import eduardoImage from './eduardo.png'
import juanCarlosImage from './juan_carlos.png'
import leoImage from './leo.png'
import luisImage from './luis.png'
import troyImage from './troy.png'
import viktorImage from './viktor.png'

import viktorDiazImage from './viktorDiaz.jpg'
import bedroomProducersBlog from './bpb.jpg'

import flStudioAudioInput from './FL_Studio-audio_input.png'
import flStudioFxSlot from './FL_Studio-FX_slot.png'
import flStudioMidiInput from './FL_Studio-MIDI_input.png'
import flStudioTranskrV2 from './FL_Studio-Transkr_V2.png'
import flStudioChannel from './FL_Studio-channel.png'
import flStudioAsioPanel from './FL_Studio-ASIO_panel.png'

import logicProAudioDevices from './Logic_Pro-Audio_Devices.png'
import logicProSettingsAudioDevices from './Logic_Pro-Settings-Audio_Devices.png'
import logicProSettingsMidiInputs from './Logic_Pro-Settings-MIDI_Inputs.png'
import logicProCreateNewTrackAudio from './Logic_Pro-Create_New_Track-Audio.png'
import logicProTrackAudio1 from './Logic_Pro-Track-Audio_1.png'
import logicProAudio1TranskrPlugIn from './Logic_Pro-Audio_1-Transkr_Plug-In.png'
import logicProCreateNewTrackMidi from './Logic_Pro-Create_New_Track-MIDI.png'
import logicProTrackClassicClean from './Logic_Pro-Track-Classic_Clean.png'

import bitwigStudioSettingsAudio from './Bitwig_Studio-Settings-Audio.png'
import bitwigStudioSettingsControllers from './Bitwig_Studio-Settings-Controllers.png'
import bitwigStudioMixerAudio2 from './Bitwig_Studio-Mixer_Panel-Audio_2.png'
import bitwigStudioMixerInst1 from './Bitwig_Studio-Mixer_Panel-Inst_1.png'
import bitwigStudioTranskrV2 from './Bitwig_Studio-Audio_2-Transkr_V2.png'

import garageBandSettingsAudioMidi from './GarageBand-Settings-Audio_MIDI.png'
import garageBandCreateNewTrackMicOrLine from './GarageBand-Create_New_Track-Mic_or_Line.png'
import garageBandAudio1PlugIns from './GarageBand-Audio_1-Plug-ins.png'
import garageBandCreateNewTrackMidi from './GarageBand-Create_New_Track-MIDI.png'
import garageBandSoftwareInstrumentGuitar from './GarageBand-Software_Instrument-Guitar.png'
import garageBandTranskrV2 from './GarageBand-Transkr_V2.png'

import reaperPreferencesAudioDevice from './REAPER-Preferences-Audio-Device.png'
import reaperPreferencesAudioMidiInputs from './REAPER-Preferences-Audio-MIDI_Inputs.png'
import reaperAddFxToTrack1 from './REAPER-Add_FX_to_Track_1.png'
import reaperFxTrack2 from './REAPER-FX-Track_2.png'
import reaperTranskrV2 from './REAPER-Transkr_V2.png'

import cubaseProAudioSystem from './cubase_pro-audio_system.png'
import cubaseProMidiPortSetup from './cubase_pro-midi_port_setup.png'
import cubaseProAddAudioTrack from './cubase_pro-add_audio_track.png'
import cubaseProInsertOther from './cubase_pro-insert_other.png'
import cubaseProAddInstrumentTrack from './cubase_pro-add_instrument_track.png'
import cubaseProTranskrV2 from './cubase_pro-transkr_v2.png'

// import scarlett2i2DeviceSettings from './Scarlett_2i2_Device_Settings.png'

import pitchPerfektGui from './Pitch_Perfekt_GUI.png'

const images = {
	logoText,
	robotHead,
	notes,
	robotLogo,
	transkrV2Gui,
	fretboardGui,
	scanToPay,
	promoCodeImage,
	westerbergLogo,
	benjaminImage,
	eduardoImage,
	juanCarlosImage,
	leoImage,
	luisImage,
	troyImage,
	viktorImage,
	viktorDiazImage,
	garageBandSettingsAudioMidi,
	garageBandCreateNewTrackMicOrLine,
	garageBandAudio1PlugIns,
	garageBandCreateNewTrackMidi,
	garageBandSoftwareInstrumentGuitar,
	garageBandTranskrV2,
	logicProAudioDevices,
	logicProSettingsAudioDevices,
	logicProCreateNewTrackAudio,
	logicProSettingsMidiInputs,
	logicProTrackAudio1,
	logicProAudio1TranskrPlugIn,
	logicProCreateNewTrackMidi,
	logicProTrackClassicClean,
	flStudioAudioInput,
	flStudioFxSlot,
	flStudioMidiInput,
	flStudioTranskrV2,
	flStudioChannel,
	flStudioAsioPanel,
	bitwigStudioSettingsAudio,
	bitwigStudioSettingsControllers,
	bitwigStudioMixerAudio2,
	bitwigStudioMixerInst1,
	bitwigStudioTranskrV2,
	reaperPreferencesAudioDevice,
	reaperPreferencesAudioMidiInputs,
	reaperAddFxToTrack1,
	reaperFxTrack2,
	reaperTranskrV2,
	pitchPerfektGui,
	cubaseProAudioSystem,
	cubaseProMidiPortSetup,
	cubaseProAddAudioTrack,
	cubaseProInsertOther,
	cubaseProAddInstrumentTrack,
	cubaseProTranskrV2,
	bedroomProducersBlog
}

export default images
