import React from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import './style.scss'

const accepted = (acceptedByScrolling) => {
	window.gtag_sendEvent('consent_accept', {
		accepted_by_scrolling: acceptedByScrolling
	})

  window.gtag_allConsentGranted()
  window.fbq_grantConsent()
}

const declined = () => {
	window.gtag_sendEvent('consent_declined')
}

const googlePrivacyUrl = 'https://business.safety.google/privacy/'
const metaPrivacyUrl = 'https://www.facebook.com/privacy/policy/'

const cookieName = '_arx_user_consent'

const UserConsent = () => {
  if (Cookies.get(cookieName) === 'true')
    accepted(false)

  return (
    <CookieConsent
      cookieName={cookieName}
      location='bottom'
      buttonText='Accept'
      onAccept={accepted}
      enableDeclineButton
      declineButtonText='Decline'
      onDecline={declined}
      // flipButtons
      containerClasses='alert user-consent-container'
      contentClasses='user-consent-content'
      buttonClasses='user-consent-button aria-hidden'
      declineButtonClasses='user-decline-button'
      disableStyles
      overlay
      // debug
    >
      This website uses cookies and we need your consent to share some of your personal data with Google and Meta.
      Learn more about how they process personal information 
      {' '}
      <a href={googlePrivacyUrl} target='_blank' rel='noreferrer'>here</a> and <a href={metaPrivacyUrl} target='_blank' rel='noreferrer'>here</a>.
    </CookieConsent>
  )
}

export default UserConsent
