import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import { FormTitle } from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons'
import './style.scss'

const product = 'Pitch Perfekt'

const downloaded = (os) => {
	window.gtag_sendEvent('download_click', {
		product: product,
		operating_system: os
	})

	window.fbq_standardEvent('Lead', {
		product: product,
		OperatingSystem: os
	});
}

const macOsDownloaded = () => downloaded('macOS')
const windowsDownloaded = () => downloaded('Windows')

const macUrl = '/download/Pitch_Perfekt_macOS-arm64-x86_64.zip'
const windowsUrl = '/download/Pitch_Perfekt_Windows-x86_64.zip'

const PitchPerfektDownload = () => (
	<section id='pitch-perfekt-download'>
		<Container /* className='zindex2' */>
			{/* <FormTitle text='DOWNLOAD' /> */}
			{/*
			<div className='row'>
				<div className='col-lg-4 download-p'>
					<div className='download-head'>
						<h2>Mobile App</h2>
						<h3>Guitar Recorder And Melody Creator</h3>
					</div>
					<div className='download-details'>
						<p>
							Capture your ideas on the spot and make sure you do not miss
							out on writing your next hit. Our unique app will save
							your compositions for later and accurately convert them into
							melodies.
							REMOVE: Are you a beginner? No worries!
							Learn and practice scales more efficiently.
						</p>
						<a
							href='https://play.google.com/apps/testing/com.algoriffix.jammin'
							target='_blank'
							rel='noreferrer'
						>
							Join Test
						</a>
					</div>
				</div>
				<div className='col-lg-4 col-sm-6'>
					<div className='download-item download-item-4'>
						<div className='download-icon di-4'>
							<i className='fa fa-android' aria-hidden />
						</div>
						<h3>Android</h3>
						<p>
							Android 7.0 (64-bit) or higher
						</p>
						<a
							href='https://play.google.com/store/apps/details?id=com.algoriffix.jammin'
							target='_blank'
							rel='noreferrer'
						>
							Download
						</a>
					</div>
				</div>
				<div className='col-lg-4 col-sm-6'>
					<div className='download-item download-item-3'>
						<div className='download-icon di-3'>
							<i className='fa fa-windows' aria-hidden />
						</div>
						<h3>Windows</h3>
						<p>
							Windows 10 (64-bit)
						</p>
						<a href='/download/transcr-plugin-windows-x64.zip' download>Download</a>
					</div>
				</div>
			</div>
			*/}
			<Row>
				<Col lg={4} className='download-p'>
					<div className='download-head'>
						<h2>Pitch Perfekt</h2>
						<h3>Free as in Beer</h3>
					</div>
					<div className='download-details'>
						<p>
							Capture your live performance or convert your voice recordings to melodies.
							Never hit a wrong note again with scale enforcement.<br />
							<br />
							It&apos;s completely free to use!
						</p>
						<a href="/guide">User Guide</a>
					</div>
				</Col>
				<Col lg={4} sm={6}>
					<div className='download-item download-item-2'>
						<div className='download-icon di-2'>
							<FontAwesomeIcon icon={faApple} />
						</div>
						<h3>Mac</h3>
						<p>
							macOS 10.13 or later<br />
							ARM64 or x86-64 CPU<br />
							AU, VST3 and standalone
						</p>
						<a href={macUrl} download onClick={macOsDownloaded}>
							Download
						</a>
					</div>
				</Col>
				<Col lg={4} sm={6}>
					<div className='download-item download-item-3'>
						<div className='download-icon di-3'>
							<FontAwesomeIcon icon={faWindows} />
						</div>
						<h3>PC</h3>
						<p>
							Windows 10 or later<br />
							x86-64 CPU<br />
							VST3 and standalone
						</p>
						<a href={windowsUrl} download onClick={windowsDownloaded}>
							Download
						</a>
					</div>
				</Col>
			</Row>
		</Container>
	</section>
)

export default PitchPerfektDownload
