import React from 'react'
import {
	Container, Row, Col, Image,
} from 'react-bootstrap'
// import 'pure-react-carousel/dist/react-carousel.es.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faVideo } from '@fortawesome/free-solid-svg-icons'
// import VideoPlayer from '../../VideoPlayer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAudio,  faMicrophoneLines, faMusic, faStopwatch, faUsers, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

const { pitchPerfektGui } = images

const PitchPerfektFeatures = () => (
	<section id='pitch-perfekt-features'>
		<Container>
			<Row className='plugin-p'>
				<Col lg={6} md={10} className='m-md-auto plugin-img'>
					<Image src={pitchPerfektGui} alt='Pitch Perfekt' fluid />
					{/*
					<div className='plugin-overlay'>
						<VideoPlayer url='https://youtu.be/pddT15Dd0tc'>
							<div className='plugin-button'>
								<FontAwesomeIcon icon={faVideo} />
							</div>
						</VideoPlayer>
					</div>
					*/}
				</Col>
				<Col lg={6} className='plugin-text'>
					<h2>Pitch Perfekt</h2>
					<h3>Voice to MIDI for All and Everyone</h3>
					<p className='plugin-p-padding'>
						Turn your voice into a MIDI controller
					</p>
					<p><FontAwesomeIcon icon={faMicrophoneLines} />Pitch detection</p>
					<p><FontAwesomeIcon icon={faMusic} />Key and note tracking</p>
					<p><FontAwesomeIcon icon={faWandMagicSparkles} />Scale enforcement</p>
					<p><FontAwesomeIcon icon={faUsers} />Harmonisation</p>
					<p><FontAwesomeIcon icon={faStopwatch} />Low latency</p>
					<p><FontAwesomeIcon icon={faFileAudio} />File conversion</p>
				</Col>
			</Row>
		</Container>
	</section>
)

export default PitchPerfektFeatures
