import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import ReactPlayer from 'react-player'
import './style.scss'

const VideoPlayer = ({ children, url, onPlay }) => (
	<Popup trigger={children} modal className='video-player-popup'>
		<ReactPlayer url={url} playing onPlay={onPlay} />
	</Popup>
)

export default VideoPlayer
